import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { saveAsset } from 'store/assets/actions';
import { originApi } from 'services/admin-bff/origin-rtk';
import { scheduleCreate, scheduleUpdate, scheduleDelete } from 'store/schedules/actions';
import { SCHEDULES_ACTION, SCHEDULES_SCOPE } from 'store/schedules/constants';
import { useForm, SubmitHandler } from 'react-hook-form';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { useAssetSchedulePublishTime, useNotificationSchedule } from 'store/schedules/hooks';
import { useModifyAssetPodmeMetadataMutation } from 'services/admin-bff-sdk/generated';
import { usePrevious } from 'hooks/usePrevious';
import deepDiff from 'deep-diff';
import { getTimestamp } from 'lib/time';
import { resolver } from '../validator';
import type { FormValues, SubmitOptions } from '../types';
import { useFormValues } from './useFormValues';
import { getAssetChanges, adjustFlightTimes } from './utils';
import { useAssetFormPersistent } from './useAssetFormPersistent';
import { useSendToPodme } from './useSendToPodme';
import { usePushNotifications } from './submit/usePushNotifications';
import { useDynamicFields } from './useDynamicFields';

export const useAssetForm = (asset: Asset) => {
    const dispatch = useDispatch();

    const assetSchedulePublishTime = useAssetSchedulePublishTime({ id: asset.id, provider: asset.provider });
    const notificationSchedule = useNotificationSchedule({ id: asset.id, provider: asset.provider });
    const sendToPodme = useSendToPodme({ provider: asset.provider, assetId: asset.id });
    const formValues = useFormValues({
        asset,
        assetSchedulePublishTime,
        notificationSchedule,
        sendToPodme,
    });

    const [changeAssetRestrictions] = originApi.useChangeAssetRestrictionsMutation();
    const formApi = useForm<FormValues>({
        defaultValues: formValues,
        resolver,
        mode: 'onBlur',
    });

    const { onSubmitAction } = useAssetFormPersistent(asset.id, formApi);

    const [modifyPodmeMetadata] = useModifyAssetPodmeMetadataMutation();

    const handlePushNotifications = usePushNotifications();

    const { handleSubmit, formState, reset, getFieldState, setError, control } = formApi;
    const { errors: formErrors, defaultValues } = formState;

    const isSubmittingPrevious = usePrevious(formState.isSubmitting);
    const { isDynamicField } = useDynamicFields({ asset, control });

    useEffect(() => {
        if (formState.isSubmitting) {
            return;
        }
        if (isSubmittingPrevious && isEmpty(formErrors)) {
            reset(formValues, {
                keepDirty: false,
                keepTouched: false,
                keepErrors: false,
                keepDirtyValues: false,
            });
        } else {
            const diff = deepDiff(formValues, defaultValues);
            if (!diff) {
                return;
            }
            reset(formValues, {
                keepDirty: true,
                keepTouched: true,
                keepErrors: true,
                keepDirtyValues: true,
            });
        }
    }, [formValues, reset, formState.isSubmitting, isSubmittingPrevious, formErrors, defaultValues]);

    const onSubmit =
        (status?: Asset['status'], options?: SubmitOptions): SubmitHandler<FormValues> =>
        async (values) => {
            const changes = getAssetChanges(formValues, values, asset, { ...options, isDynamicField });
            adjustFlightTimes(changes, formValues, values, asset, status);

            if (options?.shouldBackToLive) {
                changes.streamType = 'live';
                changes.duration = 0;
                changes.flightTimes = {
                    start: getTimestamp(new Date()),
                    end: null,
                };
            }

            if ('sendToPodme' in changes) {
                try {
                    await modifyPodmeMetadata({
                        provider: asset.provider,
                        assetId: asset.id,
                        metadata: { sendToPodme: values.sendToPodme },
                    });
                } catch (error) {
                    setError('root.serverError', { message: 'Cannot change Podme metadata' });
                    return;
                } finally {
                    delete changes.sendToPodme;
                }
            }

            if (changes.protections) {
                try {
                    const result = await changeAssetRestrictions({
                        provider: asset.provider,
                        assetId: asset.id,
                        protections: values.protections,
                    });
                    if ('data' in result && !result.data) {
                        setError('root.serverError', { message: 'Cannot change viewer access' });
                        return;
                    }
                } catch (error) {
                    setError('root.serverError', { message: 'Cannot change viewer access' });
                    return;
                } finally {
                    delete changes.protections;
                }
            }

            await new Promise((resolve, reject) => {
                dispatch(
                    saveAsset({
                        id: asset.id,
                        provider: asset.provider,
                        changes: { ...changes, status },
                        resolve,
                        reject,
                    })
                );
            });

            const commonScheduleProps = {
                provider: asset.provider,
                assetId: asset.id,
                scope: SCHEDULES_SCOPE.ASSET,
                action: SCHEDULES_ACTION.PUBLISH,
            };
            if (assetSchedulePublishTime && (values.schedulePublishTime === null || status === 'active')) {
                dispatch(scheduleDelete(commonScheduleProps));
            } else if (values.schedulePublishTime !== assetSchedulePublishTime && values.schedulePublishTime !== null) {
                if (!assetSchedulePublishTime) {
                    dispatch(scheduleCreate({ ...commonScheduleProps, time: values.schedulePublishTime }));
                } else {
                    dispatch(scheduleUpdate({ ...commonScheduleProps, time: values.schedulePublishTime }));
                }
            }

            await handlePushNotifications(formValues, values, status, asset);

            onSubmitAction();
        };

    return {
        formApi,
        formErrors,
        defaultValues,
        getFieldState,
        onSubmit: (status?: Asset['status'], options?: SubmitOptions) => handleSubmit(onSubmit(status, options)),
    };
};

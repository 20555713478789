import z from 'zod';
import type { Asset, Category } from '@schibsted-svp/svp-api-types';

export const dynamicFieldSchema = z
    .object({
        type: z.enum(['text', 'date', 'select', 'checkbox']),
        key: z.string().optional(),
        label: z.string(),
        group: z.string(),
        options: z.array(z.object({ label: z.string(), value: z.string() })).optional(),
        minDate: z.union([z.literal('today'), z.number()]).optional(),
        helper: z.string().optional(),
        hideInFilters: z.boolean().optional(),
        showFor: z
            .object({
                assetType: z.array(z.enum(['audio', 'video'])).optional(),
                streamType: z.array(z.enum(['vod', 'live', 'wasLive'])).optional(),
            })
            .optional(),
        defaultValue: z.union([z.string(), z.number(), z.boolean()]).optional(),
    })
    .strict();

export type DynamicField = z.infer<typeof dynamicFieldSchema>;

export type DynamicFieldGrouped = Omit<DynamicField, 'group'>;

type DynamicFieldGroup = {
    group: DynamicField['group'];
    fields: DynamicFieldGrouped[];
};

const DYNAMIC_FIELD_METADATA_PREFIX = 'custom_field';

export function groupDynamicFields(dynamicFields: DynamicField[]) {
    return dynamicFields.reduce((accumulator, { group, ...rest }) => {
        const groupIndex = accumulator.findIndex((field) => field.group === group);
        if (groupIndex !== -1) {
            accumulator[groupIndex].fields.push(rest);
        } else {
            accumulator.push({ group, fields: [rest] });
        }
        return accumulator;
    }, [] as DynamicFieldGroup[]);
}

export function parseDynamicFieldConfig(key: string, value: string) {
    try {
        const parsedValue = dynamicFieldSchema.parse(JSON.parse(value));
        return {
            key: key.replace(`${DYNAMIC_FIELD_METADATA_PREFIX}_`, ''),
            ...parsedValue,
        };
    } catch (error) {
        return undefined;
    }
}

export function getDynamicFields(category: Category | undefined): DynamicField[] {
    if (!category) {
        return [];
    }

    const { metadata } = category.additional || {};

    return Object.entries(metadata)
        .filter(([key]) => key.startsWith(DYNAMIC_FIELD_METADATA_PREFIX))
        .map(([key, { value }]) => parseDynamicFieldConfig(key, value))
        .filter(Boolean);
}

export function filterDynamicFieldsForAsset(dynamicFields: DynamicField[], asset: Asset): DynamicField[] {
    return dynamicFields.filter(
        ({ showFor: { assetType, streamType } = {} }) =>
            (!assetType || assetType.includes(asset.assetType)) &&
            (!streamType || streamType.includes(asset.streamType))
    );
}

export function shouldWrapInLabeledContainer(field: DynamicField) {
    return field.type !== 'checkbox';
}

import type { Control, UseFieldArrayReturn } from 'react-hook-form';
import { LabeledContainer } from '@schibsted-svp/react-ui';
import QuestionMark from 'components/ui/QuestionMark';
import { DynamicFieldGrouped, shouldWrapInLabeledContainer } from '../../helpers/DynamicForm';
import type { FormValues } from '../../types';
import { DynamicField } from './DynamicField';

type DynamicFieldContainerProps = {
    field: DynamicFieldGrouped;
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
};

export function DynamicFieldContainer({
    field,
    control,
    setValue,
    simpleMetadataControls,
}: DynamicFieldContainerProps) {
    if (shouldWrapInLabeledContainer(field)) {
        return (
            <LabeledContainer
                label={
                    <>
                        {field.label}
                        {field.helper && <QuestionMark content={field.helper} />}
                    </>
                }
            >
                <DynamicField
                    field={field}
                    control={control}
                    setValue={setValue}
                    simpleMetadataControls={simpleMetadataControls}
                />
            </LabeledContainer>
        );
    }

    return (
        <div>
            <DynamicField
                field={field}
                control={control}
                setValue={setValue}
                simpleMetadataControls={simpleMetadataControls}
            />
            {field.helper && <QuestionMark content={field.helper} />}
        </div>
    );
}

import { Controller, type Control, type UseFieldArrayReturn, type UseFormSetValue } from 'react-hook-form';
import type { DynamicFieldGrouped } from '../../helpers/DynamicForm';
import type { FormValues } from '../../types';
import { DynamicFieldGenerator } from './DynamicFieldGenerator';

type DynamicFieldProps = {
    field: DynamicFieldGrouped;
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;

    simpleMetadataControls: UseFieldArrayReturn<FormValues, 'additional.metadata.simpleMetadata'>;
};

export function DynamicField({ field, control, setValue, simpleMetadataControls }: DynamicFieldProps) {
    const index = simpleMetadataControls.fields.findIndex(({ key }) => key === field.key);

    if (index === -1) {
        const appendField = (value?: string) => {
            simpleMetadataControls.append({ key: field.key, value: value || '' }, { shouldFocus: true });
        };
        return <DynamicFieldGenerator dynamicField={field} onChange={appendField} setValue={setValue} />;
    }

    return (
        <Controller
            name={`additional.metadata.simpleMetadata.${index}.value`}
            control={control}
            render={({ field: controllerField }) => (
                <DynamicFieldGenerator dynamicField={field} controllerField={controllerField} setValue={setValue} />
            )}
        />
    );
}

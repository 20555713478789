import set from 'lodash/set';
import unset from 'lodash/unset';
import get from 'lodash/get';
import { diff } from 'deep-diff';
import { getMetadata } from 'store/assets/selectors';
import type { Asset } from '@schibsted-svp/svp-api-types';
import { isActive, isAudio } from 'models/asset';
import { getTimestamp } from 'lib/time';
import type { FormValues, SubmitOptions } from '../types';

const fieldsFilter = ['metadata', 'series', 'subtitles', 'schedulePublishTime', 'pushNotifications'] as const;

function getSeriesChanges({ series }: FormValues) {
    if (!series) {
        return undefined;
    }
    if (!series.seasonNumber && !series.episodeNumber) {
        return null;
    }
    return series;
}

export function getSimpleMetadataObject(simpleMetadata: { key: string; value: string }[]) {
    return simpleMetadata.reduce((prev, current) => ({ ...prev, [current.key]: current.value }), {});
}

export function getMergedMetadata(
    metadata: FormValues['additional']['metadata'],
    asset: Asset,
    options?: SubmitOptions
): Asset['additional']['metadata'] {
    const { simpleMetadata, multiPreviewsMetadata, ...restMetadata } = metadata;

    const mergedMetadata = Object.entries({
        ...getSimpleMetadataObject(simpleMetadata),
        ...getMetadata(asset.additional.metadata),
        ...multiPreviewsMetadata,
        ...restMetadata,
        ...(options?.shouldBackToLive && {
            protocol: null,
            source: null,
            chunkDuration: null,
            liveDuration: null,
        }),
    })
        .filter(([key, value]) => {
            if (Array.isArray(value)) {
                return value.length > 0;
            }

            return options?.isDynamicField(key) || Boolean(value);
        })
        .map(([key, value]) => [key, value.toString()]);

    return Object.fromEntries(mergedMetadata);
}

export function adjustFlightTimes(
    changes: Partial<FormValues>,
    formValues: FormValues,
    values: FormValues,
    asset: Asset,
    status: Asset['status']
) {
    const isPublished = !isActive(asset) && status === 'active';
    const currentTime = getTimestamp(new Date());

    if (isAudio(asset)) {
        if (values.schedulePublishTime) {
            set(changes, 'flightTimes.start', values.schedulePublishTime);
        } else if (isPublished) {
            set(changes, 'flightTimes.start', currentTime);
        }
        return;
    }

    if (values.schedulePublishTime) {
        set(changes, 'flightTimes.start', asset.created);
    } else if (
        isPublished &&
        !changes.flightTimes?.start &&
        (!values.flightTimes?.start || values.flightTimes?.start < currentTime)
    ) {
        set(changes, 'flightTimes.start', currentTime);
    }
}

export function getAssetChanges(
    formValues: FormValues,
    values: FormValues,
    asset: Asset,
    options?: SubmitOptions
): Partial<FormValues> {
    const changesToSend: Partial<FormValues> = {};
    const changes = diff(formValues, values) || [];

    changes.forEach((change) => {
        const { path, kind } = change;

        // skip fields that are set manually
        if (fieldsFilter.some((fieldFilter) => path.includes(fieldFilter))) {
            return;
        }

        if (kind === 'A' || kind === 'E') {
            set(changesToSend, path, get(values, path));
        }
    });
    const isFieldChanged = (field: string) => changes.some(({ path }) => path.includes(field));

    if (isFieldChanged('cuePoints')) {
        set(changesToSend, 'additional.cuePoints', values.additional.cuePoints);
    }
    if (isFieldChanged('tags')) {
        set(changesToSend, 'additional.tags', values.additional.tags);
    }

    if (isFieldChanged('series')) {
        set(changesToSend, 'series', getSeriesChanges(values));
    }

    if (isFieldChanged('metadata') || options?.shouldBackToLive) {
        set(changesToSend, 'additional.metadata', getMergedMetadata(values.additional.metadata, asset, options));
    }

    if (isFieldChanged('authors')) {
        const { authors } = values.additional.metadata;
        if (authors.length > 0) {
            set(changesToSend, 'additional.metadata.authors', JSON.stringify(authors));
        }
    }

    if (isFieldChanged('movie_cast')) {
        const { movie_cast: movieCast } = values.additional.metadata;
        if (movieCast.length > 0) {
            set(changesToSend, 'additional.metadata.movie_cast', JSON.stringify(movieCast));
        }
    }

    if (isFieldChanged('contentType')) {
        const { contentType } = values.additional.metadata;

        if (contentType !== 'documentary') {
            unset(changesToSend, 'additional.metadata.movie_cast');
            unset(changesToSend, 'additional.metadata.movie_director');
            unset(changesToSend, 'additional.metadata.movie_releasedAt');
        }
    }

    if (isFieldChanged('subtitles')) {
        set(changesToSend, 'additional.subtitles', values.additional.subtitles);
    }

    return changesToSend;
}

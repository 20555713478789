import type { ChangeEvent, MouseEvent } from 'react';
import type { ControllerRenderProps, UseFormSetValue } from 'react-hook-form';
import { Input, Select, Checkbox, selectComponents } from '@schibsted-svp/react-ui';
import { DateTimeMerged } from 'components/video/edit/Form/DateTimeMerged';
import { findSingleOption, getValueFromOption } from 'components/ui/Form/Select';
import { combine } from 'lib/function';
import { MdSync } from 'react-icons/md';
import type { DynamicFieldGrouped } from '../../helpers/DynamicForm';
import type { FormValues } from '../../types';
import css from './DynamicFieldGenerator.module.scss';

type DynamicFieldGeneratorProps = {
    dynamicField: DynamicFieldGrouped;
    controllerField?: ControllerRenderProps<FormValues, `additional.metadata.simpleMetadata.${number}.value`>;
    onChange?: (value?: string) => void;
    setValue: UseFormSetValue<FormValues>;
};

type CustomIndicatorsWithRegenerateType = selectComponents.ClearIndicatorProps & {
    onRegenerateClick: (event: MouseEvent) => void;
};

function CustomIndicatorsWithRegenerate({ children, onRegenerateClick, ...props }: CustomIndicatorsWithRegenerateType) {
    return (
        <selectComponents.IndicatorsContainer {...props}>
            <MdSync className={(css.regenerateIcon, css.backtoDefaultIcon)} onClick={onRegenerateClick} />
            {children}
        </selectComponents.IndicatorsContainer>
    );
}

export function DynamicFieldGenerator({
    dynamicField,
    controllerField,
    onChange,
    setValue,
}: DynamicFieldGeneratorProps) {
    const { type, label, options, defaultValue, minDate = 0 } = dynamicField;

    const { value: formValue } = controllerField || {};

    if (defaultValue && formValue === undefined) {
        onChange(defaultValue.toString());
    }

    const combinedOnChange = combine(controllerField?.onChange, onChange);

    const handleRegenerateClick = (event: MouseEvent) => {
        event.preventDefault();
        setValue(controllerField?.name, defaultValue.toString(), { shouldDirty: true, shouldTouch: true });
    };
    /* eslint eqeqeq: 0 */
    const shouldBackToDefault = defaultValue && formValue != defaultValue;

    if (type === 'text') {
        return (
            <Input
                {...controllerField}
                onChange={(event) => combinedOnChange(event.currentTarget.value)}
                size="small"
                icon={
                    <span className={css.regenerateIcon} onClick={handleRegenerateClick}>
                        {shouldBackToDefault ? <MdSync /> : null}
                    </span>
                }
                iconPosition="right"
            />
        );
    }

    if (type === 'date') {
        return (
            <span className={css.regenerateContainer}>
                <DateTimeMerged
                    input={{
                        ...controllerField,
                        value: Number(controllerField?.value) || '',
                        onChange: (value) => combinedOnChange(value ? String(value) : ''),
                    }}
                    meta={{ error: '' }}
                    minDate={minDate === 'today' ? new Date() : new Date(minDate * 1000)}
                    clearToNull
                />
                {shouldBackToDefault ? (
                    <MdSync className={(css.regenerateIcon, css.backtoDefaultIcon)} onClick={handleRegenerateClick} />
                ) : null}
            </span>
        );
    }

    if (type === 'select') {
        const backToDefaultControls = shouldBackToDefault
            ? {
                  components: {
                      IndicatorsContainer: (props) => (
                          <CustomIndicatorsWithRegenerate {...props} onRegenerateClick={handleRegenerateClick} />
                      ),
                  },
              }
            : {};
        return (
            <Select
                {...controllerField}
                value={findSingleOption(options)(controllerField?.value)}
                onChange={(value) => combinedOnChange(value ? getValueFromOption(value) : '')}
                options={options}
                isClearable
                splitButton={false}
                size="small"
                {...backToDefaultControls}
            />
        );
    }

    if (type === 'checkbox') {
        return (
            <span className={css.regenerateContainer}>
                <Checkbox
                    {...controllerField}
                    checked={controllerField?.value === 'true'}
                    onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        combinedOnChange(event.currentTarget.checked.toString())
                    }
                    label={label}
                    containerClassName={css.checkboxContainer}
                />
                {shouldBackToDefault ? (
                    <MdSync className={(css.regenerateIcon, css.backtoDefaultIcon)} onClick={handleRegenerateClick} />
                ) : null}
            </span>
        );
    }

    return null;
}
